<template>
  <div class="e-dialogswrapper">
    <transition name="slide-to-left">
      <MessageList
        v-show="!dialogOpen"
        :items="sentMessages"
        :show-new-dialog-button="false"
      />
    </transition>

    <transition
      mode="out-in"
      name="dialog-container"
      @before-enter="loadDialogs"
    >
      <DialogWrapper
        v-if="dialogOpen"
        :key="'dialog' + currentDialogId"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import DialogWrapper from 'organisms/DialogWrapper/DialogWrapper'
import MessageList from 'organisms/MessageList/MessageList'

export default {
  name: 'SentTemplate',
  components: {
    DialogWrapper,
    MessageList
  },
  computed: {
    ...mapGetters(['currentDialogId', 'currentRouteName', 'dialog', 'sentMessages', 'dialogOpen']),
    dialogState() {
      return {
        dialogId: this.currentDialogId,
        currentRoute: this.currentRouteName
      }
    },
    dialogSelected() {
      return typeof this.currentDialogId !== 'undefined'
    }
  },
  watch: {
    dialogState: function() {
      this.setDialogOpen(this.dialogSelected)
    }
  },
  async mounted() {
    this.getSentMessages()

    if (this.currentDialogId) {
      await Promise.all([this.getDialogs(), this.loadDialogs()])
      this.setDialogOpen(this.dialogSelected)
    }
  },
  methods: {
    ...mapActions(['getSentMessages', 'setDialogOpen', 'getDialog', 'getDialogs']),
    async loadDialogs() {
      this.getDialog(this.currentDialogId)
    }
  }
}
</script>

<style lang="scss">
</style>
